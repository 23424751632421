import { Heading, Text } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Section from '@nutkit/component-section';
import { useBreakpoint, breakpoints, breakpointDirections } from '@nutkit/react-utils';

import styles from './RegisterEmailAboutNutmeg.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.register.aboutNutmeg';

const RegisterEmailAboutNutmeg = () => {
  const { t } = useTranslation();
  const { matchesCondition: isLessThanLargeBreakpoint } = useBreakpoint(breakpoints.LG, breakpointDirections.DOWN);

  return (
    <Section className={styles.container}>
      {isLessThanLargeBreakpoint && <Heading level="2">{t(`${TRANSLATION_NAMESPACE}.mobile.title`)}</Heading>}
      <Text>{t(`${TRANSLATION_NAMESPACE}.content`)}</Text>
      <Text>{t(`${TRANSLATION_NAMESPACE}.disclaimer`)}</Text>
    </Section>
  );
};

export default RegisterEmailAboutNutmeg;