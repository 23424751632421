import React from 'react';
import { useLocation } from 'react-router-dom';
import { shape, string, bool, oneOfType } from 'prop-types';
import { Pot, SupportArticles, Login } from '@nm-utils-lib-web/routes';
import NutkitSideMenu, { SideMenuHeader, SideMenuItem, SideMenuItemGroup } from '@nutkit/component-side-menu';
import { IconChartAreaSimple, IconHelpFilled } from '@nutkit/component-icon';
import Avatar, { avatarSizes } from '@nutkit/component-avatar';
import { OrganisationLogo } from '@nm-ui-shared-lib-web/organisation-header';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Flags, useFlag } from '@nm-utils-lib-web/flags';

import { trackNonInvestorClickHelp } from '../../../tracking';
import { UPDATE_QUERY_PARAM } from '../../../constants/CustomerConsents';
import { CUSTOMER_MARKETING_INTERCEPT_PATH, NON_INVESTOR_SETTINGS } from '../../../constants/RouterConstants';

const { SIGNUP_HOST } = global.NutmegConfig;
const TRANSLATION_NAMESPACE = 'signup.nonInvestor.navigation';
const DATA_QA_SIDE_MENU_ITEM = 'side-menu-item';

/** @type {import('./SideMenu.types').SideMenu} */
export const SideMenu = ({ userDetails, userDetailsError, userDetailsLoading, ...otherProps }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const email = userDetails?.email || '';
  const custodianAccountNumber = userDetails?.custodianAccountNumber;
  const displayUserEmailOrFallback = !userDetailsError ? email : t(`${TRANSLATION_NAMESPACE}.fallBackUser.label`);
  const displayUserDetail = userDetailsLoading
    ? t(`${TRANSLATION_NAMESPACE}.loading.label`)
    : displayUserEmailOrFallback;
  const isAdaptiveMFAPhase1Enabled = useFlag(Flags.ADAPTIVE_MFA_PHASE_1);

  return (
    <NutkitSideMenu
      {...otherProps}
      location={location}
      header={({ onToggle, isOpen, isExpanded }) => (
        <SideMenuHeader onToggle={onToggle} isOpen={isOpen} isExpanded={isExpanded} logo={OrganisationLogo} />
      )}
    >
      <SideMenuItemGroup
        fieldPrimary={displayUserDetail}
        fieldSecondary={custodianAccountNumber}
        image={<Avatar name={displayUserEmailOrFallback} size={avatarSizes.SM} />}
        data-qa={`${DATA_QA_SIDE_MENU_ITEM}-group-personal`}
      >
        {isAdaptiveMFAPhase1Enabled ? (
          <SideMenuItem
            noStack
            isInternal
            label={t(`${TRANSLATION_NAMESPACE}.accountSettings.label`)}
            href={`${NON_INVESTOR_SETTINGS}`}
          />
        ) : (
          <SideMenuItem
            noStack
            isInternal
            label={t(`${TRANSLATION_NAMESPACE}.updatePreferences.label`)}
            href={`${CUSTOMER_MARKETING_INTERCEPT_PATH}?${UPDATE_QUERY_PARAM}=true`}
          />
        )}
        <SideMenuItem
          noStack
          label={t(`${TRANSLATION_NAMESPACE}.signOut.label`)}
          href={`${SIGNUP_HOST}${Login.LOGOUT_PATH}`}
          data-qa={`${DATA_QA_SIDE_MENU_ITEM}-sign-out`}
        />
      </SideMenuItemGroup>

      <SideMenuItem
        icon={IconChartAreaSimple}
        isActive
        label={t(`${TRANSLATION_NAMESPACE}.dashboard.label`)}
        href={Pot.DASHBOARD_PATH}
        data-qa={`${DATA_QA_SIDE_MENU_ITEM}-dashboard`}
        noStack
      />

      <SideMenuItem
        onClick={trackNonInvestorClickHelp}
        icon={IconHelpFilled}
        label={t(`${TRANSLATION_NAMESPACE}.help.label`)}
        href={SupportArticles.SUPPORT_ROOT}
        data-qa={`${DATA_QA_SIDE_MENU_ITEM}-help`}
        noStack
      />
    </NutkitSideMenu>
  );
};

/**
 * NOTE: there are several missing prop types here, on per prop expected by
 * NutkitSideMenu, which are not declared in the prop types.
 *
 * Ideally we'd declare them in the type definition and stop using prop-types.
 * There are plugins capable of generate runtime-evaluated prop-types based on
 * TS type definition, we should use those instead.
 */
SideMenu.propTypes = {
  userDetails: oneOfType([
    shape({
      email: string.isRequired,
      custodianAccountNumber: string.isRequired
    }),
    shape({})
  ]).isRequired,
  userDetailsError: bool,
  userDetailsLoading: bool
};

SideMenu.defaultProps = {
  userDetailsError: false,
  userDetailsLoading: false
};

export default SideMenu;
