import React from 'react';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';

import HalfLayout from '../../layouts/HalfLayout';
import Footer from '../Footer';

import styles from './RegisterEmailPage.module.scss';
import RegisterEmailBanner from './RegisterEmailBanner';
import RegisterEmail from './RegisterEmail';
import useSignupNotification from '../../hooks/useSignupNotification';

const layoutSizes = {
  sm: {
    size: 12,
    offset: 0,
  },
  md: {
    size: 12,
    offset: 0,
  },
  lg: {
    size: 6,
    offset: 0,
  },
  xl: {
    size: 6,
    offset: 0,
  },
};

const RegisterEmailPage = () => {
  const Notification = useSignupNotification(true);
  const showNotification = !!Notification;

  return (
    <>
      <ThemeProvider themeName={themeNames.DARK}>
        <OrganisationHeader
          data-qa="register-header"
          translationNameSpace="signup.common.uiSharedOrganisationHeader"
          logoHref={window.NutmegConfig.MARKETING_HOST}
          showHelp
          isOpen={false}
          noStack
        />
      </ThemeProvider>
      {showNotification && <Notification data-qa="maintenance-notification-banner" />}
      <HalfLayout
        left={<RegisterEmailBanner />}
        right={<RegisterEmail />}
        leftLayoutSizes={layoutSizes}
        rightLayoutSizes={layoutSizes}
        className={styles['layout-container']}
      />
      <Footer noStack />
    </>
  );
};

export default RegisterEmailPage;
