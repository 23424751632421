import React, { useEffect } from 'react';
import { node } from 'prop-types';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { PersonalisedOnboardingBanner } from '@nm-ui-shared-lib-web/banner';
import Section, { sectionTagNames, stackSpacing } from '@nutkit/component-section';

import { trackSignupBannerVisible } from '../../../tracking';
import useSignupNotification from '../../../hooks/useSignupNotification';
import HalfLayout from '../../../layouts/HalfLayout';
import Footer from '../../Footer';

import AdaptiveContainer from './AdaptiveContainer';
import RegisterBanner from './RegisterBanner';

/** @type {import('./RegisterLayout.types').RegisterLayout} */
const RegisterLayout = ({ children }) => {
  const { matchesCondition: isLessThanMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const Notification = useSignupNotification(true);
  const showNotification = !!Notification;
  const isPersonalisedOnboardingFlagEnabled = useFlag(Flags.PERSONALISED_ONBOARDING);

  useEffect(() => {
    if (showNotification) {
      trackSignupBannerVisible();
    }
  }, [showNotification]);

  return (
    <>
      <ThemeProvider themeName={themeNames.DARK}>
        <OrganisationHeader
          data-qa="register-header"
          translationNameSpace="signup.common.uiSharedOrganisationHeader"
          logoHref={window.NutmegConfig.MARKETING_HOST}
          showHelp
          isOpen={false}
          noStack={!isLessThanMediumBreakpoint || showNotification || isPersonalisedOnboardingFlagEnabled}
        />
      </ThemeProvider>
      {showNotification && <Notification data-qa="maintenance-notification-banner" />}
      {isPersonalisedOnboardingFlagEnabled && (
        <Section tagName={sectionTagNames.DIV} stackSpacing={isLessThanMediumBreakpoint && stackSpacing.MD}>
          <PersonalisedOnboardingBanner />
        </Section>
      )}
      <HalfLayout
        left={<RegisterBanner isMobile={isLessThanMediumBreakpoint} />}
        right={<AdaptiveContainer>{children}</AdaptiveContainer>}
      />
      <Footer noStack />
    </>
  );
};

RegisterLayout.propTypes = {
  children: node.isRequired
};

export default RegisterLayout;
