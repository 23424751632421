import React, { FC } from "react";

const IconPassport: FC = props => (
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <g clip-path="url(#clip0_735_4264)">
    <path d="M3 5.495C3 4.393 3.893 3.5 4.995 3.5H19.005C20.107 3.5 21 4.393 21 5.495V19.505C21 20.0341 20.7898 20.5415 20.4157 20.9157C20.0415 21.2898 19.5341 21.5 19.005 21.5H4.995C4.46589 21.5 3.95846 21.2898 3.58432 20.9157C3.21019 20.5415 3 20.0341 3 19.505V5.495ZM6.357 18.5H17.847C17.2026 17.5734 16.3435 16.8166 15.3431 16.2942C14.3427 15.7717 13.2306 15.4993 12.102 15.5C10.9734 15.4993 9.86134 15.7717 8.86091 16.2942C7.86048 16.8166 7.00137 17.5734 6.357 18.5ZM12 13.5C12.4596 13.5 12.9148 13.4095 13.3394 13.2336C13.764 13.0577 14.1499 12.7999 14.4749 12.4749C14.7999 12.1499 15.0577 11.764 15.2336 11.3394C15.4095 10.9148 15.5 10.4596 15.5 10C15.5 9.54037 15.4095 9.08525 15.2336 8.66061C15.0577 8.23597 14.7999 7.85013 14.4749 7.52513C14.1499 7.20012 13.764 6.94231 13.3394 6.76642C12.9148 6.59053 12.4596 6.5 12 6.5C11.0717 6.5 10.1815 6.86875 9.52513 7.52513C8.86875 8.1815 8.5 9.07174 8.5 10C8.5 10.9283 8.86875 11.8185 9.52513 12.4749C10.1815 13.1313 11.0717 13.5 12 13.5Z" fill="#384143" />
  </g>
  <defs>
    <clipPath id="clip0_735_4264">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
    </clipPath>
  </defs>
</svg>
);

export default IconPassport;
