import LegacyUserService from './LegacyUserService';

import handleError from './errorHandler';

type CreateIpbCustomerBody = {
  customerUuid: string;
}

export type CreateIpbCustomer = (options: {
  email: string;
}) => Promise<CreateIpbCustomerBody | undefined>;

const createIpbCustomer: CreateIpbCustomer = async ({ email }) => {
  try {
    const { data } = await LegacyUserService.post('/v1/create_ipb_customer', {
      email
    });

    /**
     * NOTE: we're missing a validation layer like yup or zod to confirm at
     * runtime the values we expect from the API are actually present. As a
     * result, there's no type safe way to narrow it down. For the time being we
     * use `as`.
     */
    return data as CreateIpbCustomerBody;
  } catch (error) {
    handleError(error, 'Error creating ipb customer');
  }
};

export default createIpbCustomer;
