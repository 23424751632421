import React, { FC } from 'react';

const IconHome: FC = props => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_0_4699" mask-type="luminance" maskUnits="userSpaceOnUse" x="1" y="2" width="22" height="19">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.8 20.7V14.1H14.2V20.7H19.7V11.9H23L12 2L1 11.9H4.3V20.7H9.8Z" fill="white" />
    </mask>
    <g mask="url(#mask0_0_4699)">
      <rect x="-1.2002" y="-2.39999" width="26.4" height="26.4" fill="#181819" />
    </g>
  </svg>
);

export default IconHome;
